import React from 'react'
import CardItem from "./cardItem"
import translations from "./translations.json"

import dk from "../assets/flag-icons/flags/4x3/dk.svg"
import se from "../assets/flag-icons/flags/4x3/se.svg"
import no from "../assets/flag-icons/flags/4x3/no.svg"
import fi from "../assets/flag-icons/flags/4x3/fi.svg"
import gb from "../assets/flag-icons/flags/4x3/gb.svg"
import de from "../assets/flag-icons/flags/4x3/de.svg"
import fr from "../assets/flag-icons/flags/4x3/fr.svg"
import nl from "../assets/flag-icons/flags/4x3/nl.svg"

export default function Card(props) {

    const { location } = props

    return (
        <div className="flex h-screen">
            <div className="bg-slate-100 w-1/2 border-solid border-2 border-zinc-500 rounded-md m-auto max-w-[550px]">
                <h2 className="text-black pb-5 text-center text-xl font-semibold pt-3">Nettoparts</h2>
                <div className="flex flex-wrap px-3 pb-3 gap-3">
                    {
                        location === "Belgium"
                        ?
                            <>
                                <CardItem flag={fr} translations={translations.fr}/>
                                <CardItem flag={nl} translations={translations.nl}/>
                            </>
                        :
                            <>
                                <CardItem flag={dk} translations={translations.dk}/>
                                <CardItem flag={se} translations={translations.se}/>
                                <CardItem flag={no} translations={translations.no}/>
                                <CardItem flag={fi} translations={translations.fi}/>
                                <CardItem flag={gb} translations={translations.gb}/>
                                <CardItem flag={de} translations={translations.de}/>
                                <CardItem flag={fr} translations={translations.fr}/>
                                <CardItem flag={nl} translations={translations.nl}/>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}