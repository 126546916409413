import React from 'react'

export default function CardItem(props) {

    const { flag, translations } = props

    function navigate(){
        window.location.replace(translations.site, {replace: true})
    }

    return (
        <>
            <button className="grow basis-4/12 border-solid border-slate-50 border-2" onClick={() => navigate()}>
                <img src={flag} alt="flag icon" className="w-[30%] m-auto" />
                <h3 className="text-black">{translations.goto_btn}</h3>
            </button>

        </>
    )
}