import { useEffect } from 'react';

export default function Redirect(props) {

    const { location } = props

    useEffect(() => {
        if(location === "Denmark" || location === "Greenland" || location === "Faroe Islands"){
            window.location.replace("https://nettoparts.dk", {replace: true})
          }else if(location === "Sweden"){
            window.location.replace("https://nettoparts.se", {replace: true})
          }else if(location === "Norway"){
            window.location.replace("https://nettoparts.no", {replace: true})
          }else if(location === "Finland"){
            window.location.replace("https://nettoparts.fi", {replace: true})
          }else if(location === "Britain (UK)" || location === "Ireland"){
            window.location.replace("https://sparesonweb.com", {replace: true})
          }else if(location === "Germany" || location === "Austria" || location === "Switzerland"){
            window.location.replace("https://nettoparts.de", {replace: true})
          }else if(location === "France"){
            window.location.replace("https://nettoparts.fr", {replace: true})
          }else if(location === "Netherlands"){
            window.location.replace("https://nettoparts.nl", {replace: true})
          }
    })

    return null
}