import './App.css';
import { useState, useEffect } from 'react';
import Card from './components/card'
import Spinner from './components/spinner'
import Redirect from './components/redirect'
import { getUserLocation } from './lib/geo'

function App() {

  let location = getUserLocation()
  const known_locations = [
    "Denmark",
    "Greenland",
    "Faroe Islands",
    "Sweden",
    "Finland",
    "Norway",
    "Britain (UK)",
    "Ireland",
    "Germany",
    "Austria",
    "Switzerland",
    "France",
    "Netherlands",
  ]

  const country_codes = [
    { name: "Denmark", code: "dk" },
    { name: "Greenland", code: "gl" },
    { name: "Faroe Islands", code: "fo" },
    { name: "Sweden", code: "se" },
    { name: "Finland", code: "fi" },
    { name: "Norway", code: "no" },
    { name: "Britain (UK)", code: "gb" },
    { name: "Ireland", code: "ie" },
    { name: "Germany", code: "de" },
    { name: "Austria", code: "at" },
    { name: "Switzerland", code: "ch" },
    { name: "France", code: "fr" },
    { name: "Netherlands", code: "nl" },
  ]

  console.log(location)

  const [ipgeolocation, setIpgeolocation] = useState();
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(
          "https://nettoparts.org/location"
        )
      ).json();

      // set state when the data received
      setIpgeolocation(data);
      setIsloading(false)
    };

    dataFetch();
  }, []);

  console.log(ipgeolocation)

  // known_locations.includes(location.country) && location.country === ipgeolocation.country_name

  return (
    <main className="landscape:bg-horizontal portrait:bg-vertical bg-no-repeat bg-cover">
      {
        isloading
          ?
          <div className="flex h-screen">
            <div className="m-auto">
            <Spinner />
            </div>
          </div>
          :
          <>
            {
              known_locations.includes(location.country) && location.country === ipgeolocation.country_name
                ?
                <div className="flex h-screen">
                  <div className="m-auto">


                    <Spinner />
                    <Redirect location={location.country} />
                  </div>
                </div>
                :
                <Card location={location.country} />
            }
          </>
      }

    </main>
  );
}

export default App;
